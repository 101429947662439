<template>
    <b-card title="Payment Adjustment Type">
        <b-form>
            <b-row>
                <b-col md="4">
                    <b-form-group label="Payment Settlement Type">
                        <b-form-input v-model="paymentSettlement" placeholder="Please Enter Payment Settlement Type">
                        </b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-checkbox v-model="status" class="mt-2 mr-2">
                        This payment adjustment type is inactive
                    </b-form-checkbox>
                </b-col>
                <b-col md="3">
                    <b-form-checkbox v-model="selectedAsDefault" class="mt-2 mr-2">
                        Do not set as default in payment adjust mode select options
                    </b-form-checkbox>
                </b-col>
                <b-col md="2" class="text-center">
                    <b-button block variant="primary" class="mt-2 mr-2" @click="addUpdatePaymentAdjustmentTypes">{{
                            addUpdateButton
                    }}</b-button>
                </b-col>
            </b-row>
            <hr>
            <div>
                <!-- search input -->
                <div class="custom-search d-flex justify-content-end">
                    <b-form-group>
                        <div class="d-flex align-items-center">
                            <label class="mr-1">Search</label>
                            <b-form-input v-model="searchTerm" placeholder="Search" type="text"
                                class="d-inline-block" />
                        </div>
                    </b-form-group>
                </div>

                <!-- table -->
                <vue-good-table :line-numbers="true" :columns="columns" :rows="rows" :rtl="direction" :search-options="{
                    enabled: true,
                    externalQuery: searchTerm
                }" :select-options="{
    enabled: false,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
}" :pagination-options="{
    enabled: true,
    perPage: pageLength
}">
                    <template slot="table-row" slot-scope="props">

                        <!-- Column: Name -->
                        <span v-if="props.column.field === 'selectedAsDefault'" class="text-nowrap">
                            <span v-if="props.row.selectedAsDefault === true" class="text-nowrap">
                                <feather-icon icon="CheckIcon" class="isActive" />
                            </span>
                            <span v-if="props.row.selectedAsDefault === false" class="text-nowrap">
                                <feather-icon icon="XIcon" class="isInActive" />
                            </span>
                        </span>

                        <!-- Column: Name -->
                        <span v-else-if="props.column.field === 'status'" class="text-nowrap">
                            <span v-if="props.row.status === true" class="text-nowrap">
                                <feather-icon icon="CheckIcon" class="isActive" />
                            </span>
                            <span v-if="props.row.status === false" class="text-nowrap">
                                <feather-icon icon="XIcon" class="isInActive" />
                            </span>
                        </span>

                        <!-- Column: Action -->
                        <span v-else-if="props.column.field === 'action'">
                            <span>
                                <b-button
                                @click="editButton(props.row.id, props.row.paymentSettlement, props.row.status, props.row.selectedAsDefault)"
                                    variant="flat-primary"><feather-icon icon="EditIcon"
                                        class="text-body align-middle mr-25" /></b-button>
                                <b-button @click="deletePaymentAdjustmentTypes(props.row.id)" variant="flat-primary"><feather-icon
                                        icon="TrashIcon" class="text-body align-middle mr-25" /></b-button>
                            </span>
                        </span>

                        <!-- Column: Common -->
                        <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                        </span>
                    </template>

                    <!-- pagination -->
                    <template slot="pagination-bottom" slot-scope="props">
                        <div class="d-flex justify-content-between flex-wrap">
                            <div class="d-flex align-items-center mb-0 mt-1">
                                <span class="text-nowrap ">
                                    Showing 1 to
                                </span>
                                <b-form-select v-model="pageLength" :options="['10', '20', '50','100']" class="mx-1"
                                    @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                                <span class="text-nowrap"> of {{ props.total }} entries </span>
                            </div>
                            <div>
                                <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number
                                    last-number align="right" prev-class="prev-item" next-class="next-item"
                                    class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                                    <template #prev-text>
                                        <feather-icon icon="ChevronLeftIcon" size="18" />
                                    </template>
                                    <template #next-text>
                                        <feather-icon icon="ChevronRightIcon" size="18" />
                                    </template>
                                </b-pagination>
                            </div>
                        </div>
                    </template>
                </vue-good-table>
            </div>
        </b-form>
    </b-card>
</template>
  
<script>
import {
    BAvatar, BFormCheckbox, BCard, BRow, BCol, BForm, BButton, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from '@/store/api'

export default {
    components: {
        VueGoodTable, BFormCheckbox,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem, BCard, BRow, BCol, BForm, BButton
    },
    data() {
        return {
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: 'Payment Settlement',
                    field: 'paymentSettlement',
                },
                {
                    label: 'Status',
                    field: 'status',
                },
                {
                    label: 'Default Select',
                    field: 'selectedAsDefault',
                },
                {
                    label: 'Action',
                    field: 'action',
                },
            ],
            rows: [],
            searchTerm: '',
            paymentSettlement: '',
            status: '',
            selectedAsDefault: ''

        }
    },
    methods: {
        getPaymentAdjustmentTypes() {
            this.id = 0;
            this.paymentSettlement = '';
            this.status = '';
            this.selectedAsDefault = '';
            this.addUpdateButton = "Add";
            let self = this;

            var axios = require('axios');
            var data = '';

            axios(api.getApi('get', '/masters/paymentAdjustmentTypes', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));

                    self.rows = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        addUpdatePaymentAdjustmentTypes() {
            let self = this;
            if (!(self.paymentSettlement == '')) {
                if (this.addUpdateButton == 'Add') {
                    var axios = require('axios');
                    var data = JSON.stringify({ "paymentSettlement": this.paymentSettlement, "status": this.status, "selectedAsDefault": this.selectedAsDefault });
                    axios(api.getApi('Post', '/masters/paymentAdjustmentTypes', data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Payment Adjustment Added Successfully!`,
                                    icon: 'SaveIcon',
                                    variant: 'success',
                                },
                            })
                            self.getPaymentAdjustmentTypes();
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                } else {
                    let self = this;
                    var axios = require('axios');
                    var data = JSON.stringify({ "paymentSettlement": this.paymentSettlement, "status": this.status, "selectedAsDefault": this.selectedAsDefault });
                    axios(api.getApi('put', '/masters/paymentAdjustmentTypes/' + this.id, data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.getPaymentAdjustmentTypes();
                            self.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Payment Adjustment Updated Successfully!`,
                                    icon: 'SaveIcon',
                                    variant: 'success',
                                },
                            })

                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            } else {
                this.$swal({
                    title: '',
                    text: "Please Enter Payment Adjustment Type Name.",
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            }
        },
        deletePaymentAdjustmentTypes(id) {
            let self = this;
            this.$swal({
                title: '',
                text: "Are You Sure You Want To Delete!",
                icon: 'error',
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    var axios = require('axios');
                    var data = '';
                    axios(api.getApi('delete','/masters/paymentAdjustmentTypes/'+id,data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.getPaymentAdjustmentTypes();
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            })
        },
        editButton(id, paymentSettlement, status, selectedAsDefault) {
            this.id = id;
            this.addUpdateButton = 'Update';
            this.paymentSettlement = paymentSettlement;
            this.status = status;
            this.selectedAsDefault =selectedAsDefault;
        }
    },
    computed: {

    },
    created() {
        this.getPaymentAdjustmentTypes();
    },
}
</script>
<style lang="css">
@import '~vue-good-table/dist/vue-good-table.css';
</style>
<style>
.isActive {
    color: green;
    width: 26px;
    height: 26px;
}

.isInActive {
    color: red;
    width: 26px;
    height: 26px;
}
</style>
   